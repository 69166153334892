<template>
  <div class="providers-auth" :class="wrapperClasses">
    <StButton
      v-if="isGoogleEnabled"
      type="gray"
      class="provider-button"
      :label="
        isMobile
          ? t('registration.googleButtonShortMobile')
          : t('registration.googleButtonShort')
      "
      :size="isMobile ? 'l' : 'xl'"
      icon="c-google"
      is-left-icon
      data-t="google-button"
      :disabled="isDisabledProviders"
      @click="handleGoogleSignUp"
    />

    <StButton
      class="provider-button"
      type="gray"
      :label="
        isMobile
          ? t('registration.telegramButtonMobile')
          : t('registration.telegramButton')
      "
      :size="isMobile ? 'l' : 'xl'"
      icon="c-telegram"
      is-left-icon
      data-t="telegram-sign-in"
      :disabled="isDisabledProviders"
      :loading="isTgProcessing"
      @click="handleTelegramSignIn"
    />
  </div>
</template>

<script setup lang="ts">
import { useTelegramAuthWidget } from '@st/telegram/composables/useTelegramAuthWidget'
import { useGoogleRedirect } from '../../../composables/useGoogleRedirect'
import { useUserStore } from '../../../stores/useUserStore'

const props = defineProps<{
  isMobile: boolean
  isGoogleEnabled: boolean
  isEmailEnabled: boolean
  isDisabledProviders: boolean
  convertedReferralCode?: string
  convertedPromocodeName?: string
}>()

const emit = defineEmits<{
  (e: 'finishTg'): void
  (e: 'handleTelegramSignIn'): void
}>()

const { t, locale } = useI18n()
const { open } = useToast()
const { handleTelegramAuth } = useTelegramAuthWidget()
const { tryToAuthenticate } = useUserStore()

const providersSignUpParams = computed(() =>
  props.convertedReferralCode
    ? {
        language: locale.value,
        referralCode: props.convertedReferralCode ?? '',
      }
    : {
        language: locale.value,
        promocodeName: props.convertedPromocodeName ?? '',
      },
)

const isTgProcessing = ref(false)
async function handleTelegramSignIn() {
  try {
    isTgProcessing.value = true
    await handleTelegramAuth(providersSignUpParams.value)
    await tryToAuthenticate()
    emit('finishTg')
  } catch {
    open({
      label: t('authorization.errorMessages.somethingWentWrong'),
      type: 'negative',
    })
  } finally {
    isTgProcessing.value = false
  }
}

const { handleGoogleRedirect } = useGoogleRedirect()
function handleGoogleSignUp() {
  handleGoogleRedirect(providersSignUpParams.value)
}

const wrapperClasses = computed(() => ({
  wrap: !props.isEmailEnabled,
  mobile: props.isMobile,
}))
</script>

<style scoped>
.providers-auth {
  overflow: hidden;
  display: flex;
  gap: var(--spacing-100);
  align-items: center;
  justify-content: space-between;

  &.wrap {
    flex-wrap: wrap;
  }

  .provider-button {
    width: 100%;
    min-width: 0;
  }
}

.providers-auth.mobile {
  flex-direction: column;
}
</style>
