<template>
  <WelcomeBonusCard
    :id="welcomeBonus.userFreespinProgramId"
    :badge="badge"
    :amount="amount"
    :title="title"
    :is-mobile="isMobile"
    type="freespin"
  />
</template>

<script setup lang="ts">
import { useCurrenciesStore } from '@st/payments/stores/useCurrenciesStore'
import WelcomeBonusCard from './WelcomeBonusCard.vue'
import type { Freespin } from '../../../types'

const props = defineProps<{
  welcomeBonus: Freespin
  isMobile?: boolean
}>()

const { getCurrencyById } = useCurrenciesStore()
const { t } = useI18n()

const { format } = useCurrencyFormatter({
  currency: computed(() => {
    const currencyId = props.welcomeBonus.freespinCurrencyId
    return getCurrencyById(currencyId)?.code ?? 'USD'
  }),
})

const badge = computed(() => {
  let denomination = 0
  if (
    props.welcomeBonus.freespinBetAmount &&
    props.welcomeBonus.freespinNumber
  ) {
    denomination = +props.welcomeBonus.freespinBetAmount
  }

  const formattedAmount = format(denomination)
  return t('welcomeBonus.freespinBadge', { amount: formattedAmount })
})

const title = computed(() =>
  t('welcomeBonus.freespinsTitle', {
    amount: props.welcomeBonus.freespinNumber,
  }),
)

const amount = computed(() => {
  if (
    props.welcomeBonus.freespinBetAmount &&
    props.welcomeBonus.freespinNumber
  ) {
    const sum =
      +props.welcomeBonus.freespinBetAmount * props.welcomeBonus.freespinNumber

    return format(sum)
  }
  return ''
})
</script>
