<template>
  <WelcomeBonusCard
    :id="tooltipId"
    :amount="welcomeBonus.amount"
    :currency-id="welcomeBonus.currencyId"
    :title="t('welcomeBonus.reload')"
    :is-mobile="isMobile"
    type="reload"
  />
</template>

<script setup lang="ts">
import WelcomeBonusCard from './WelcomeBonusCard.vue'
import type { ReloadProgram } from '../../../types'

const props = defineProps<{
  welcomeBonus: ReloadProgram
  isMobile?: boolean
}>()

const { t } = useI18n()

const tooltipId = computed(() => `reload${props.welcomeBonus.bonusProgramId}`)
</script>
