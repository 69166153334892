<template>
  <article class="email-disabled" :class="platform">
    <div class="loader">
      <StIcon name="envelope" size="48" />
      <div class="circle">
        <StSpinner size="16" />
      </div>
    </div>
    <div class="text">
      <p class="title">
        {{ t('registration.emailDisabled.title') }}
      </p>
      <p class="subtitle">
        {{ t('registration.emailDisabled.subtitle') }}
      </p>
    </div>
  </article>
</template>

<script setup lang="ts">
const { t } = useI18n()

const { platform } = usePlatform()
</script>

<style scoped>
.email-disabled {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-250);
  align-items: center;

  padding: var(--spacing-350) var(--spacing-350) var(--spacing-300);

  background: radial-gradient(
    75.1% 77.69% at 50% 50%,
    #111118 34.01%,
    #1f1f29 100%
  );
  border-radius: var(--border-radius-100);
}

.text {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);
  text-align: center;
}

.title {
  margin: 0;
  font: var(--desktop-text-sm-semibold);
  color: var(--text-primary);
}

.subtitle {
  margin: 0;
  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);
}

.loader {
  position: relative;
}

.circle {
  position: absolute;
  right: -10px;
  bottom: 6px;

  padding: var(--spacing-050);

  line-height: 0;

  background-color: var(--icon-brand);
  border: solid 3px #191921;
  border-radius: var(--border-radius-full);
}

.email-disabled.mobile {
  gap: var(--spacing-150);
  padding: var(--spacing-200) var(--spacing-200) var(--spacing-250);

  .text {
    gap: var(--spacing-075);
  }

  .loader {
    transform: scale(0.85);
  }

  .title {
    margin: 0;
    font: var(--mobile-text-semibold);
    color: var(--text-primary);
  }

  .subtitle {
    margin: 0;
    font: var(--mobile-caption-1-regular);
    color: var(--text-secondary);
  }
}
</style>
