<template>
  <WelcomeBonusCard
    :id="welcomeBonus.id"
    :badge="t('welcomeBonus.freebetBadge')"
    :amount="welcomeBonus.amount"
    :currency-id="welcomeBonus.currencyId"
    :title="t('welcomeBonus.freebet')"
    :is-mobile="props.isMobile"
    type="freebet"
  />
</template>

<script setup lang="ts">
import WelcomeBonusCard from './WelcomeBonusCard.vue'
import type { Freebet } from '../../../types'

const props = defineProps<{
  welcomeBonus: Freebet
  isMobile?: boolean
}>()
const { t } = useI18n()
</script>
