import { debounce } from '@st/utils'

export function useCheckPromocode(promocodeInputValue: Ref<string>) {
  const { t } = useI18n()

  const convertedReferralCode = computed(() => {
    if (
      !promocodeInputValue.value.length ||
      promocodeInputValue.value[0] !== 'r'
    )
      return undefined

    return promocodeInputValue.value
  })

  const convertedPromocodeName = computed(() => {
    if (
      !promocodeInputValue.value.length ||
      promocodeInputValue.value[0] === 'r'
    )
      return undefined

    return promocodeInputValue.value.toUpperCase()
  })

  const {
    getToken: getPromocodeToken,
    recaptchaQueryParam: promocodeRecaptcha,
  } = useRecaptcha()
  const { execute: validatePromocode, error: validatePromocodeError } =
    useStFetch('/promocode/validate', {
      method: 'post',
      body: computed(() => ({
        promocodeName: convertedPromocodeName.value || '',
      })),
      immediate: false,
      watch: false,
      query: promocodeRecaptcha,
    })

  const promocodeErrorMessage = computed(() => {
    if (!convertedPromocodeName.value) return undefined

    switch (validatePromocodeError.value?.data?.error) {
      case 'PROMOCODE_NOT_FOUND':
        return t('registration.errorMessages.promocodeNotFound')
      case 'PROMOCODE_CAN_NOT_BE_USED_BY_STATUS':
        return t('registration.errorMessages.promocodeCanNotBeUsedByStatus')
      case 'PROMOCODE_CAN_NOT_BE_USED_BY_DATES':
        return t('registration.errorMessages.promocodeCanNotBeUsedByDates')
      case 'PROMOCODE_CAN_NOT_BE_USED_BY_USAGE_LIMIT':
        return t('registration.errorMessages.promocodeCanNotBeUsedByUsageLimit')
      case 'VALIDATION_ERROR':
        return t('registration.errorMessages.validationError')
      default:
        return undefined
    }
  })

  async function checkPromocode() {
    if (!promocodeRecaptcha.value.recaptchaToken) {
      await getPromocodeToken('/promocode/validate')
    }
    validatePromocode()
  }

  const validatePromocodeDebounce = debounce(checkPromocode, 500)

  watch(convertedPromocodeName, (newPromocodeName) => {
    if (!newPromocodeName) {
      return
    }

    validatePromocodeDebounce()
  })

  return {
    convertedReferralCode,
    convertedPromocodeName,
    promocodeErrorMessage,
  }
}
