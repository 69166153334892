import { minBy } from '@st/utils'
import { useDepositBonusesStore } from '../../stores/useDepositBonusesStore'
import { useRegularBonusesStore } from '../../stores/useRegularBonusesStore'
import { useFreebetsStore } from '../../stores/useFreebetsStore'
import { useFreespinsStore } from '../../stores/useFreespinsStore'

export function useWelcomeBonus() {
  const { parseDate } = useDate()

  const {
    favoriteNewDepositBonus: depositBonus,
    isLoading: isDepositsLoading,
  } = storeToRefs(useDepositBonusesStore())
  const { reloadPrograms, isCashbackListLoading } = storeToRefs(
    useRegularBonusesStore(),
  )
  const { notDepositFreebets, isLoading: isFreebetsLoading } =
    storeToRefs(useFreebetsStore())
  const { notDepositFreespins, isLoading: isFreespinsLoading } =
    storeToRefs(useFreespinsStore())

  const reloadBonus = computed(() =>
    minBy(reloadPrograms.value, (program) =>
      parseDate(program.accrualAt).unix(),
    ),
  )
  const freebetBonus = computed(() =>
    minBy(notDepositFreebets.value, (freebet) =>
      parseDate(freebet.expiredAt).unix(),
    ),
  )
  const freespinBonus = computed(() =>
    minBy(notDepositFreespins.value, (freespin) =>
      parseDate(freespin.expiredAt).unix(),
    ),
  )

  const hasAnyBonus = computed(
    () =>
      !!freebetBonus.value ||
      !!reloadBonus.value ||
      !freespinBonus.value ||
      !!depositBonus.value,
  )

  const isLoading = computed(
    () =>
      !!isDepositsLoading.value ||
      !!isCashbackListLoading.value ||
      !!isFreebetsLoading.value ||
      !!isFreespinsLoading.value,
  )

  return {
    freebetBonus,
    reloadBonus,
    freespinBonus,
    depositBonus,
    hasAnyBonus,
    isLoading,
  }
}
