export function useRegistrationStepper() {
  const { t } = useI18n()

  const stepQuery = useRouteQuery<'bonus' | null>('tab', null)
  const { isCurrent, goTo, goToNext, goToPrevious, current, isLast } =
    useStepper([0, 1, 2])

  const steps = computed(() => [
    {
      id: 0,
      number: 1,
      text: t('registration.firstStep'),
      isActive: isCurrent(0),
      isPassed: current.value > 0,
    },
    {
      id: 1,
      number: 2,
      text: t('registration.secondStep'),
      isActive: isCurrent(1),
      isPassed: current.value > 1,
    },
    {
      id: 2,
      number: 3,
      text: t('registration.thirdStep'),
      isActive: isCurrent(2),
      isPassed: current.value > 2,
    },
  ])

  watchEffect(() => {
    if (stepQuery.value === 'bonus') {
      goTo(2)
    }
  })

  return {
    steps,
    current,
    goToNext,
    goToPrevious,
    isCurrent,
    goTo,
    isLast,
  }
}
