<template>
  <section class="welcome-banner"></section>
</template>

<script setup lang="ts">
const { banners } = useBanners('desktopRegistration')

const bannerImage = computed(() => {
  const [firstBanner] = banners.value
  return firstBanner ? `url(${firstBanner.publicUploadUrl})` : 'none'
})
</script>

<style scoped>
.welcome-banner {
  flex-shrink: 0;
  align-self: stretch;

  width: 360px;

  background-color: var(--background-primary);
  background-image: v-bind(bannerImage);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: var(--border-radius-150);
}
</style>
