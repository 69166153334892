<template>
  <article class="bonus" :class="{ mobile: isMobile }">
    <img class="bonus-tooltip" :src="tooltipImg" alt="i" @click="openTooltip" />
    <div class="circle-check">
      <StIcon name="check" :size="isMobile ? 9 : 12" />
    </div>
    <img
      class="bonus-image"
      :src="depositImg"
      alt="bonus"
      :width="isMobile ? 48 : 60"
    />
    <h2 class="bonus-title">{{ t('bonuses.depositTitle') }}</h2>
    <div v-if="bonusesOutput.main" class="amount">
      <StIcon
        v-if="bonusesOutput.main.icon"
        :name="bonusesOutput.main.icon"
        :size="isMobile ? 18 : 24"
      />
      {{ bonusesOutput.main.amount }}
    </div>
    <div v-if="bonusesOutput.others.length" class="sub-bonuses">
      <div
        v-for="(subBonus, index) in bonusesOutput.others"
        :key="index"
        class="sub-bonus"
      >
        +
        <span>{{ subBonus?.amount }}</span>
        <StIcon
          v-if="subBonus?.icon"
          :name="subBonus.icon"
          :size="isMobile ? 14 : 16"
        />
        <span v-if="subBonus?.text" class="sub-bonus-text">
          {{ subBonus.text }}
        </span>
      </div>
    </div>
    <div class="rules-wrapper">
      <ul class="bonus-rules">
        <li>
          <StIcon v-if="!isMobile" name="check" size="16" />
          <p>
            {{ t('bonuses.depositMin') }}
            <span>
              {{ t('bonuses.depositMinValue', { amount: minDepositAmount }) }}
            </span>
          </p>
        </li>
        <li v-if="maxDepositAmount && !isMultiBonus">
          <StIcon v-if="!isMobile" name="check" size="16" />
          <p>
            {{ t('bonuses.depositMax') }}
            <span>
              {{
                t('bonuses.depositMaxValue', {
                  amount: maxDepositAmount.amount,
                })
              }}
            </span>
            <StIcon
              v-if="maxDepositAmount.bonusCurrency?.icon"
              size="16"
              :name="maxDepositAmount.bonusCurrency.icon"
            />
          </p>
        </li>
        <li v-if="rollingMultiplier && !isMultiBonus">
          <StIcon v-if="!isMobile" name="check" size="16" />
          <p>
            {{ t('bonuses.receiptCondition') }}
            <span>
              {{
                t('bonuses.conditions', {
                  multiplier: rollingMultiplier,
                })
              }}
            </span>
          </p>
        </li>
      </ul>
    </div>
  </article>
</template>

<script setup lang="ts">
import tooltipImg from '../assets/tooltip.svg'
import depositImg from '../assets/deposit.png'
import type { DepositBonus } from '../../../types'

const props = defineProps<{
  welcomeBonus: DepositBonus
  isMobile?: boolean
}>()

const { t } = useI18n()

const { welcomeBonus } = toRefs(props)

const {
  maxDepositAmount,
  isMultiBonus,
  minDepositAmount,
  bonusesOutput,
  rollingMultiplier,
} = useDepositBonus(welcomeBonus)

const router = useRouter()
function openTooltip() {
  router.push({
    query: {
      id: props.welcomeBonus.id,
      modal: 'depositBonusTooltip',
    },
  })
}
</script>

<style scoped>
.bonus-image {
  margin-top: -30px; /* stylelint-disable-line */
}

.bonus-title {
  margin: 0;
  margin-top: var(--spacing-025);
  font: var(--desktop-text-sm-semibold);
}

.amount {
  margin: 0;

  font: var(--desktop-text-2xl-semibold);
  text-shadow: 0 4px 60px rgb(249 218 144 / 32%);

  background: linear-gradient(
    109deg,
    #ffe86d 17.82%,
    #f8db90 42.56%,
    #feb581 72.33%,
    #f09f7c 81.52%
  );
  background-clip: text;

  -webkit-text-fill-color: transparent;
}

.rules-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  border-top: 1px solid var(--border-tertiary);
}

.bonus-rules {
  margin: 0;
  margin-top: var(--spacing-100);
  padding: 0;
  padding-top: var(--spacing-125);

  list-style: none;

  li {
    display: flex;
    gap: var(--spacing-075);
    align-items: center;

    min-height: 20px;

    font: var(--desktop-text-xs-semibold);
    color: var(--text-secondary);

    p {
      display: flex;
      gap: var(--spacing-025);
      align-items: center;
      margin: 0;
    }

    span {
      color: var(--text-primary);
    }
  }
}

.bonus-tooltip {
  cursor: pointer;

  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
}

.circle-check {
  position: absolute;
  z-index: 1;
  top: -5px;
  left: -5px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-050);

  color: var(--background-base);

  background: var(--icon-primary);
  border: var(--border-width-boldest) solid var(--background-base);
  border-radius: var(--border-radius-full);
}

.read-more {
  cursor: pointer;
  color: var(--text-link);
  text-decoration: none;
}

.sub-bonus {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;
  justify-content: center;

  font: var(--desktop-text-sm-semibold);
}

.bonus {
  position: relative;

  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  align-items: center;

  min-width: 250px;
  padding: var(--spacing-125) var(--spacing-150);

  background: radial-gradient(
      102.33% 102.33% at 50% 19.65%,
      rgb(253 46 0 / 70%) 0%,
      rgb(232 0 42 / 7%) 85.92%
    ),
    #20202a;
  background-color: var(--background-primary);
  background-position: center;
  background-size: cover;
  border-radius: var(--border-radius-150);
  box-shadow: 0 8px 40px 0 rgb(0 0 0 / 32%);

  &.mobile {
    min-width: 216px;
    padding: var(--spacing-150) var(--spacing-100);

    .bonus-title {
      margin-top: var(--spacing-050);
      font: var(--mobile-caption-1-medium);
    }

    .bonus-multiplier {
      /* stylelint-disable */
      font-family: 'SF Pro Display';
      font-size: 24px;
      font-weight: 600;
      font-style: normal;
      line-height: 28px;

      text-align: center;
      /* stylelint-enable */
    }

    .bonus-rules {
      li {
        justify-content: center;
        min-height: 20px;
        font: var(--mobile-caption-1-medium);
      }
    }

    .circle-check {
      padding: 3.2px; /* stylelint-disable-line */
    }

    .sub-bonus {
      font: var(--mobile-caption-1-medium);
    }
  }
}
</style>
