<template>
  <div class="promo-code">
    <StButton
      v-if="!isPromoCodeExpanded"
      :type="type"
      icon="ticket-solid"
      is-left-icon
      data-t="promocode-button"
      :label="t('registration.promocodeButton')"
      @click="expandPromoCodeInput"
    >
    </StButton>
    <StInput
      v-else
      v-model="promocodeInputValue"
      :placeholder="t('registration.promocodePlaceholder')"
      :error="!!promocodeErrorMessage && !!promocodeInputValue"
      :error-message="promocodeErrorMessage"
      :size="props.isMobile ? 'm' : 'l'"
      data-t="promocode-input"
      autofocus
    >
      <template #postfix-button>
        <StIcon
          v-if="promocodeInputValue"
          name="cross-large"
          size="16"
          class="clear-button"
          @click="clear"
        />
      </template>
    </StInput>
  </div>
</template>

<script setup lang="ts">
interface Props {
  isMobile?: boolean
  promocodeErrorMessage?: string
  modelValue: string
  type?: 'text-primary' | 'ghost'
}

const { t } = useI18n()

const props = withDefaults(defineProps<Props>(), { type: 'text-primary' })
const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const promocodeInputValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
  },
})

const referralCode = useLocalStorage('referralCode', '')
const isPromoCodeExpanded = ref(!!referralCode.value)
function expandPromoCodeInput() {
  isPromoCodeExpanded.value = true
}

function clear() {
  promocodeInputValue.value = ''
  isPromoCodeExpanded.value = false
}

watchEffect(() => {
  if (referralCode.value) {
    isPromoCodeExpanded.value = true
    promocodeInputValue.value = referralCode.value
  }
})
</script>

<style scoped>
.clear-button {
  cursor: pointer;
  color: var(--icon-tertiary);
}
</style>
